import { useState } from 'react';
import style from './Card.module.scss';
import Icon from '../../body/icon';

export default function Card(props){
    const [collapse, setCollapse] = useState('');
    var border_aux;
    var bg_aux;
    const actions = (props.actions?props.actions:'');

    {(() => {
        switch(props.border){
            case 'blue':
                border_aux = style.border__blue;
                break;
            case 'orange':
                border_aux = style.border__orange;
                break;
            case 'red':
                border_aux = style.border__red;
                break;
            default:
                border_aux = '';
        }

        switch(props.background){
            case 'blue':
                bg_aux = style.bg__blue;
                break;
            case 'orange':
                bg_aux = style.bg__orange;
                break;
            case 'red':
                bg_aux = style.bg__red;
                break;
            default:
                bg_aux = '';
        }
    })()}

    return(
        <>
            {(() => {
                if(props.empty){
                    return(
                        <div className={ style.card + ' ' + style.card__empty + ' ' + (props.size==='small'?style.card__small:'')} style={{width:(props.width?props.width:348)}}>
                            Nenhum
                        </div>
                    )
                }else{
                    return(
                        <div className={ style.card + ' ' + (props.size==='small'?style.card__small:'') } style={{width:(props.width?props.width:348)}}>
                            <div className={ style.card__header + ' ' + border_aux + ' ' + bg_aux  }>
                                <div className="d-flex align-items-center justify-content-between">
                                    <h3 className={ style.title }>
                                        { (props.title?props.title:'Card') }
                                    </h3>
                                    <span className={ style.obs1 }>
                                        { props.obs1 }
                                    </span>
                                </div>

                                {(() => {
                                    if(!props.size||props.size!=='small'){
                                        return(
                                            <div className="d-flex align-items-start justify-content-between">
                                                <h4 className={ style.subtitle }>
                                                    { props.subtitle }
                                                </h4>
                                                <span className={ style.obs2 }>
                                                    { props.obs2 }
                                                </span>
                                            </div>
                                        )
                                    }
                                })()}

                                <div className={ style.actions }>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div className={ style.left }>
                                            {(() => {
                                                if(actions){
                                                    return actions;
                                                }
                                            })()}                            
                                        </div>
                                        <div className={ style.right }>
                                            <span className={ style.obs3 + ' me-2'}>
                                                { props.obs3 }
                                            </span>
                                            <Icon type={(collapse==='show'?'close':'open')} title={(collapse==='show'?'Mostrar menos':'Mostrar mais')} onClick={() => setCollapse((collapse==='show'?'':'show'))} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={ style.card__body + ' collapse '+collapse}>
                                <div>
                                    { (props.description?<p>{props.description}</p>:'') }
                                    { props.children }
                                </div>
                            </div>
                        </div>
                    )
                }
            })()}
        </>
    )
}
