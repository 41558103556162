import Table from '../../../components/body/table';
import Tbody from '../../../components/body/table/tbody';
import Tr from '../../../components/body/table/tr';
import Td from '../../../components/body/table/tbody/td';
import Title from "../../../components/body/title";
import Row from '../../../components/body/row';
import Col from '../../../components/body/col';
import Icon from '../../../components/body/icon';

export default function Gerenciador(){
    const tipos = ['Fotos da Loja','Fotos Vitrine'];

    return(
        <>
            <Title>Tipo</Title>

            <Row>
                <Col gerenciador={true} >
                    <Table>
                        <Tbody>
                            {/* { tipos } */}
                            <Tr>
                                <Td>Fotos da Loja</Td>
                                <Td>
                                    <Icon type="editar" />
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>
                                    Fotos Vitrine
                                </Td>
                                <Td>
                                    <Icon type="editar" />
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
