import style from './Nav.module.scss';
import Item from './item';
import User from '../user';
import Logo from '../../../_assets/img/logo.png';
import { Link  } from 'react-router-dom';

export default function Nav(){
    return(
        <nav className={ style.navbar }>
            <div className={ style.logo }>
                <Link  to="/">
                    <img src={ Logo } alt="Logo" />
                </Link>
            </div>
            
            <ul>
                <Item
                    title="Comunicados"
                    link="/systems/comunicados"
                    activeClassName="active"
                />

                <Item
                    title="Jobs"
                    link="/systems/jobs"
                    activeClassName="active"
                />

                <Item
                    title="Supervisão"
                    link="/systems/supervisao"
                    activeClassName="active"
                />
            </ul>

            <User
                name="Henry Soligueti"
                info="Fastview"
            />
        </nav>
    );
}
