import { useEffect, useState, useRef } from "react";
import { MdArrowDropDown } from "react-icons/md";
import style from './thFilter.module.scss';
import Th from "../th";
import { FaTimes } from "react-icons/fa";
import Button from "../../../button";
import Input from "../../../form/input";

export default function Filter(props){
    const [filterValue, setFilterValue] = useState(null);
    const [filterActive, setFilterActive] = useState(false);
    const boxFilter = useRef(null);
    
    //FUNÇÃO PARA DETECTAR O CLIQUE FORA DO ELEMENTO
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true)
    }, []);

    //FUNÇÃO PARA DETECTAR QUANDO O USUÁRIO PRESSIONAR A TECLA ESC
    useEffect(() => {
        document.addEventListener('keydown', escFunction, false)
    }, []);

    const handleClickOutside = (e) => {
        if(!boxFilter.current.contains(e.target)){
            setFilterActive(false);
        }
    }

    const escFunction = (e) => { 
        if(e.key === 'Escape'){
            setFilterActive(false);
        }
    }

    return(
        <Th>
            <span className={ style.filter + ' ' + (filterActive===true?style.filter__active:'') } onClick={ () => setFilterActive((filterActive===true?false:true)) }>
                { props.children }

                <MdArrowDropDown />
            </span>

            <div className={ style.boxFilter + ' ' + (filterActive===true?style.boxFilter__active:'') } ref={ boxFilter }>
                <div className={ style.boxFilter__header }>
                    <span className={ style.boxFilter__title }>Filtros</span>
                    <FaTimes className={ style.boxFilter__close } onClick={ () => setFilterActive((filterActive===true?false:true)) } />
                </div>

                {(() => {
                    if(props.type==='date'){ //FILTRO DE DATA
                        return(
                            <>
                                <div className={ style.boxFilter__body }>
                                    <Input type="date" name="filtro_inicio" label="De" fullWidth={true} className="mb-2" value="" required={false} />
                                    <Input type="date" name="filtro_fim" label="Até" fullWidth={true} value="" required={false} />
                                </div>
                            </>
                        )
                    }else{ //FILTRO PADRÃO
                        return(
                            <>
                                <div className={ style.boxFilter__body }>
                                    <Input type="text" name="filtro" placeholder="Buscar..." fullWidth={true} icon="search" value={ filterValue } />
                                </div>
                            </>
                        )
                    }
                })()}

                {(() => {
                    if(props.type!=='date'){
                        let items = [props.name+' 1',props.name+' 2',props.name+' 3',props.name+' 4',props.name+' 5',props.name+' 6'].map((item, index) => {
                            return <Input type="checkbox" label={ item } id={ item } value={ item } />
                        })
                        return(                           
                            <div className={ style.checkbox__items }>
                                { items }
                            </div>
                        )
                    }
                })()}

                <div className={ style.boxFilter__footer }>
                    <div className="d-flex align-items-center justify-content-between">
                        <Button color="white" onClick={() => setFilterValue(null)}>Limpar</Button>
                        <Button>Aplicar</Button>
                    </div>
                </div>
            </div>
        </Th>
    );
}
