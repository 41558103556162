import { useState, useEffect } from "react";
import axios from "axios";
import Title from "../../../components/body/title";
import Form from '../../../components/body/form';
import Input from '../../../components/body/form/input';
import Textarea from '../../../components/body/form/textarea';
import Button from '../../../components/body/button';
import SelectReact from '../../../components/body/select';
import Row from '../../../components/body/row';
import Col from '../../../components/body/col';

export default function Novo(props){
    //FUNÇÃO PARA FORMATAR DATA
    function cd(date){
        let dateFormated = (new Date(date).getDate()<10?'0'+new Date(date).getDate():new Date(date).getDate()) + '/' + ((new Date(date).getMonth() + 1)<10?'0'+(new Date(date).getMonth() + 1):(new Date(date).getMonth() + 1)) + '/' + new Date(date).getFullYear();
        return dateFormated;
    }

    const [optionsFrequency, setOptionsFrequency] = useState([]);
    const [optionsCategory, setOptionsCategory] = useState([]);
    const [optionsSubCategory, setOptionsSubCategory] = useState([]);
    const [optionsHourLimit, setOptionsHourLimit] = useState([]);
    const [optionsJobActionPlain, setOptionsJobActionPlain] = useState([]);

    useEffect(() => {
        //OPTIONS
        axios.get('https://local.fastview.com.br/systems/job-novo/api/registry-react.php?do=get_optionsForm').then((response) => {
            setOptionsFrequency(response.data.frequency);
            setOptionsCategory(response.data.category);
            setOptionsSubCategory(response.data.subcategory);
            setOptionsHourLimit(response.data.hour_limit);
            setOptionsJobActionPlain(response.data.job_action_plain);
        }).then(() => {
            
        }) 
    },[]);

    //SETA VALORES
    const [tipo, setTipo] = useState('usuario');
    const [id, setId] = useState('');
    const [lote, setLote] = useState('');
    const [idFrequency, setIdFrequency] = useState('');
    const [dateStart, setDateStart] = useState('');
    const [dateEnd, setDateEnd] = useState('');
    const [idCategory, setIdCategory] = useState('');
    const [idSubcategory, setIdSubcategory] = useState('');
    const [hourLimit, setHourLimit] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    // const [filtroCheckboxItem, setFiltroCheckboxItem] = useState('');
    // const [priority, setPriority] = useState('');
    const [urlVideo, setUrlVideo] = useState('');
    // const [jobApi, setJobApi] = useState('');
    // const [jobSystemIntegration, setJobSystemIntegration] = useState('');
    const [jobActionPlain, setJobActionPlain] = useState('');
    const [idUser, setIdUser] = useState([window.rs_usuario_id]);

    const data = {
        tipo: tipo,
        id: id,
        lote: lote,
        id_frequency: idFrequency,
        id_category: idCategory,
        id_subcategory: idSubcategory,
        job_action_plain: jobActionPlain,
        url_video: urlVideo,
        hour_limit: hourLimit,
        description: description,        
        date_start: cd(dateStart),
        date_end: (dateEnd?cd(dateEnd):''),
        title: title,
        id_user: idUser
    }

    return(
        <>
            <Title>Novo</Title>

            <Row>
                <Col>
                    <Form action="https://local.fastview.com.br/systems/job-novo/api/registry-react.php?do=set_registry" data={data} redirect={'/systems/jobs/'+window.tokenFormated+'calendario'} >
                        <Input type="hidden" name="tipo" value={tipo} onChange={(e) => (setTipo(e.target.value))} />
                        <Input type="hidden" name="id" value={id} onChange={(e) => (setId(e.target.value))} />
                        <Input type="hidden" name="lote" value={lote} onChange={(e) => (setLote(e.target.value))} />
                        <Input type="hidden" name="filtro_checkbox_item" value="" />
                        <Input type="hidden" name="priority" value="" />
                        <Input type="hidden" name="url_video" value="" />
                        <Input type="hidden" name="job_api" value="" />
                        <Input type="hidden" name="job_system_integration" value="" />
                        <Input type="hidden" name="id_user[]" value={idUser} onChange={(e) => (setIdUser(e.target.value))} />
                        {/* <Input type="radio" name="tipo" id="lojas" label="Lojas" value="loja" />
                        <Input type="radio" name="tipo" id="usuarios" label="Usuários" value="usuario" />
                        <Input type="radio" name="tipo" id="cargos" label="Cargos" value="cargo" /> */}
                        <SelectReact label="Frequência" name="id_frequency" options={optionsFrequency} value={optionsFrequency[idFrequency]} onChange={(e) => (setIdFrequency(e.value))} />                        
                        <Input type="date" label="Data" name="date_start" value={dateStart} valueStart={new Date()} onChange={(e) => (setDateStart(e))}  />
                        <Input type="date" label="Data Final" name="date_end" value={dateEnd} valueStart={dateStart} onChange={(e) => (setDateEnd(e))} hide={(idFrequency==143||!idFrequency?true:false)} />
                        <SelectReact label="Hora limite" name="hour_limit" options={optionsHourLimit} value={optionsHourLimit[hourLimit]} onChange={(e) => (setHourLimit(e.value))} />
                        <SelectReact label="Categoria" name="id_category" options={optionsCategory} value={optionsCategory[idCategory]} onChange={(e) => (setIdCategory(e.value))} />
                        <SelectReact label="Subcategoria" name="id_subcategory" options={optionsSubCategory} value={optionsSubCategory[idSubcategory]} onChange={(e) => (setIdSubcategory(e.value))} />
                        <Input type="text" label="Título" name="title" value={title} onChange={(e) => (setTitle(e.target.value))} />
                        <Input type="text" label="URL Vídeo" name="url_video" value={urlVideo} onChange={(e) => (setUrlVideo(e.target.value))} required={false} />
                        <Textarea name="description" placeholder="Descrição" value={description} onChange={(e) => (setDescription(e.target.value))} required={false} />
                        <SelectReact label="Plano de Ação" name="job_action_plain" options={optionsJobActionPlain} value={optionsJobActionPlain[jobActionPlain]} onChange={(e) => (setJobActionPlain(e.value))} />
                        <Button type="submit">Salvar</Button>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
