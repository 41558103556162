import { useState, useEffect } from 'react';
import style from './form.module.scss';
import axios from 'axios';

export default function Form(props){
    //FUNÇÃO DE ENVIO
    function sendForm(api){
        axios.post(api, props.data).then(() => {
            if(props.redirect){
                window.location.href = props.redirect;
            }
        }).catch(() => {
            alert('Erro ao enviar, tente novamente');
        });
        //console.log(props.data);
    }

    return(
        <form
            onSubmit={(e) => (
                e.preventDefault(),
                sendForm(props.action) 
            )
        } className={ style.form } style={{width:(props.width?props.width:450)}}>
            { props.children }
        </form>
    );
}
