import { Routes, Route } from 'react-router-dom';
import Sidebar from '../../components/body/sidebar';
import SidebarLink from '../../components/body/sidebar/sidebarlink';

// PÁGINAS
import Lista from './Lista';

export default function Comunicados(){
  return (
    <>
        <Sidebar>
          <SidebarLink link="/systems/comunicados/lista" name="Lista" />
        </Sidebar>

        <Routes>
            <Route index={true} element={ <Lista /> } />
            <Route path="lista" element={ <Lista /> } />
        </Routes>
    </>
  );
}
