import Modal from 'react-bootstrap/Modal';
import style from './modalTitle.module.scss';

export default function ModalTitle(props){

    return(
        <>
            <Modal.Title className={ style.modalTitle }>
                { props.children }
            </Modal.Title>
        </>
    );
}
