import { useState } from 'react';
import style from './Input.module.scss';
import ReactDatePicker from 'react-datepicker';
import Icon from '../../icon';

export default function Input(props){
    function addDays(date, days) {
      const copy = new Date(Number(date))
      copy.setDate(date.getDate() + days)
      return copy
    }

    const [focus, setFocus] = useState(null);

    //CONFIGURAÇÕES DE LANGUAGE PRO DATEPICKER
    const defaultDays = ['D', 'S', 'T', 'Q', 'Q', 'S',' S'];
    const defaultMonths = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const locale = {
      localize: {
        day: n => defaultDays[n],
        month: n => defaultMonths[n]
      },
      formatLong: {
        date: () => 'dd/mm/yyyy'
      }
    }

    //CONVERTE DATA
    function cd(date){
      let dateFormated = (new Date(date).getDate()<10?'0'+new Date(date).getDate():new Date(date).getDate()) + '/' + ((new Date(date).getMonth() + 1<10?'0'+new Date(date).getMonth() + 1:new Date(date).getMonth() + 1)) + '/' + new Date(date).getFullYear();
      return dateFormated;
    }
    
    return(
        <div
          data-input
          className={
            style.box__input + ' ' + 
            (props.fullWidth?'w-100':'') + ' ' + 
            (props.className?props.className:'') + ' ' + 
            (props.type=='checkbox'?style.checkbox:'') + ' ' +
            (props.type=='period'?style.period:'') + ' ' +
            (focus?style.box__input_focus:'')
          } 
          style={
            {display:(props.type==='hidden'||props.hide?'none':'')}
          }>

            {(() => {
                if(props.label&&props.type!=='date'&&props.type!=='period'&&props.type!=='checkbox'&&props.type!=='radio'){
                    return(
                        <label htmlFor={ props.id }>
                            { props.label }: { (props.required===false?'':'*') }
                        </label>
                    )
                }
            })()}

            {(() => {
                if(props.type==='date'){
                    return(
                      <>
                        <label htmlFor={ props.id }>
                            { (props.label?props.label:'Data') }: { (props.required===false?'':'*') }
                        </label>
                        <ReactDatePicker
                          locale={locale}
                          dateFormat="dd/MM/yyyy"
                          selected={ props.value }
                          value={ props.value }
                          onChange={ props.onChange }
                          includeDateIntervals={[{
                            start: (props.valueStart?props.valueStart:new Date('01/01/1999')),
                            end: addDays((props.valueStart?props.valueStart:new Date('01/01/3999')),100000)
                          }]}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div className="react-datepicker__header_control">
                              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                              </button>
                              
                              <span>{defaultMonths[date.getMonth()]} {date.getFullYear()}</span>
                    
                              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                              </button>
                            </div>
                          )}
                        />
                      </>
                    )
                }else if(props.type==='period'){
                  return(
                    <>                      
                      <div className={ style.input__period }>
                        <label htmlFor={ props.id }>
                            De:
                        </label>
                        <ReactDatePicker
                          locale={locale}
                          dateFormat="dd/MM/yyyy"
                          selected={ props.valueStart }
                          value={ props.valueStart }
                          onChange={ props.onChangeStart }
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div className="react-datepicker__header_control">
                              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                              </button>
                              
                              <span>{defaultMonths[date.getMonth()]} {date.getFullYear()}</span>
                    
                              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                              </button>
                            </div>
                          )}
                        />
                      </div>

                      <div className={ style.input__period }>
                        <label htmlFor={ props.id }>
                            Até:
                        </label>
                        <ReactDatePicker
                          locale={locale}
                          dateFormat="dd/MM/yyyy"
                          selected={ props.valueEnd }
                          value={ props.valueEnd }
                          onChange={ props.onChangeEnd }
                          includeDateIntervals={[{start: props.valueStart, end: addDays(props.valueStart,100000) }]}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div className="react-datepicker__header_control">
                              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                {"<"}
                              </button>
                              
                              <span>{defaultMonths[date.getMonth()]} {date.getFullYear()}</span>
                    
                              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                {">"}
                              </button>
                            </div>
                          )}
                        />
                      </div>
                    </>
                  )
                }else{
                    return(
                        <input
                            className={ style.input }
                            type={ (props.type==='date'?'text':props.type) }
                            name={ props.name }
                            id={ props.id }
                            placeholder={ props.placeholder }
                            value={ props.value }
                            onChange={ props.onChange }
                            required={ (props.required===false?false:true) }
                            onFocus={ () => setFocus(true) }
                            onBlur={ () => setFocus(false) }
                            checked={ props.checked }
                        />
                    )
                }
            })()}   

            {(() => {
                if(props.label&&(props.type==='checkbox'||props.type==='radio')){
                    return(
                        <label htmlFor={ props.id }>
                            { props.label }
                        </label>
                    )
                }
            })()}         

            {(() => {
                if(props.icon){
                    return(
                        <Icon type={ props.icon } />
                    )
                }else{
                    if(props.type==='date'){
                        return(
                            <Icon type="calendar" title={false} />
                        )
                    }else{
                      if(props.type==='file'){
                        return(
                            <Icon type="file" />
                        )
                    }
                    }
                }
            })()}
        </div>
    );
}
