import style from './Foto.module.scss';

import { useState } from 'react';
import Icon from '../icon';

export default function Foto(props){
    const [aprovar, setAprovar] = useState((props.aprovar?true:false));
    const [reprovar, setReprovar] = useState((props.reprovar?true:false));

    return(
        <>
            <div className={ style.foto__container }>            
                <div className={ style.foto__left_container }>
                    <div className={ style.foto__left + ' ' + (props.left?'cursor-pointer':'') } style={ (props.left? { backgroundImage:'url('+props.left+')' } : {}) } onClick={props.onClickLeft}></div>
                </div>

                {(() => {
                    if(props.multiple!==false){
                        return(
                            <div className={ style.foto__right_container }>
                                <div className={ style.foto__right + ' ' + (props.right?'cursor-pointer':'') } style={ (props.right? { backgroundImage:'url('+props.right+')' } : { backgroundImage:'url('+props.left+')', opacity: .2 }) } onClick={props.onClickRight}></div>
                                {(() => {
                                    if(props.right){
                                        return(
                                            <div className={ style.foto__rate }>
                                                <div className={ style.foto__icon }>
                                                    <Icon type="aprovar" className={ (aprovar?'text-success':'') } onClick={() => (setAprovar(true), setReprovar(false))} />
                                                </div>
                                                <div className={ style.foto__icon }>
                                                    <Icon type="reprovar" className={ (reprovar?'text-danger':'') } onClick={() => (setReprovar(true), setAprovar(false))} />
                                                </div>
                                            </div>
                                        )
                                    }
                                })()}
                            </div>
                        )
                    }
                })()}
            </div>
        </>
    )
}
