import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';

/*SWIPER*/
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import '../../../_assets/css/swiper.css';

import Title from "../../../components/body/title";
import TitleFilter from '../../../components/body/title/titlefilter';
import SelectReact from "../../../components/body/select";
import Card from "../../../components/body/card";
import Form from '../../../components/body/form';
import Input from '../../../components/body/form/input';
import Textarea from '../../../components/body/form/textarea';
import Button from '../../../components/body/button';
import Icon from '../../../components/body/icon';
import PageEmpty from '../../../components/body/pageempty';
import Toast from '../../../components/body/toast';

export default function Calendario(){
    const params = useParams();
    window.token = 'teste';
    useEffect(() => {
        var tokenUrl = params.token; 
        window.token = tokenUrl;
        //var tokenUrl = 'B5Mry/8Su0Gl5yZA8MdlX9o/mMtbfJrboTVxAcE+ZNjJ6v2Qs1UwTcrDcxMxU1kS5LSJV2Dk38s0npt3UPPB76YZikqfAiIk/YiSPQHw32zOeP0lRC28ID2AgbZ7o+vl';
        var tokenUrlFormated = tokenUrl.replace(/%2F/g, '/');
        var DataKey = CryptoJS.enc.Utf8.parse("01234567890123456789012345678901");
        var DataVector = CryptoJS.enc.Utf8.parse("1234567890123412");
        var decrypted = CryptoJS.AES.decrypt(tokenUrl, DataKey, { iv: DataVector });        
        var decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
        var decryptedJson = JSON.parse(decrypted);
        console.log(decryptedJson);
        //window.token = tokenUrl;
        //window.tokenFormated = tokenUrlFormated;
        
        // window.rs_empreendimento_id = 26; 
        // window.rs_usuario_id = 24379;
        // window.rs_usuario_nome = 'Henry';

        // sessionStorage.setItem('empreendimento_id', window.rs_empreendimento_id);
        // sessionStorage.setItem('usuario_id', window.rs_usuario_id);
        // sessionStorage.setItem('usuario_nome', window.rs_usuario_nome);
    },[]);













    const [jobs, setJobs] = useState([]);
    const [swiper, setSwiper] = useState();
    const [periodStart, setPeriodStart] = useState(new Date('11/01/2022'));
    const [periodEnd, setPeriodEnd] = useState(new Date('11/30/2022'));
    const [tipo, setTipo] = useState(1);
    const [loja, setLoja] = useState(0);
    const [usuario, setUsuario] = useState(window.rs_usuario_id);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [toastShow, setToastShow] = useState(false);
    const [optionsUser, setOptionsUser] = useState([])

    //CONVERTE DATA
    function cd(date){
        let dateFormated = (new Date(date).getDate()<10?'0'+new Date(date).getDate():new Date(date).getDate()) + '/' + ((new Date(date).getMonth() + 1)<10?'0'+(new Date(date).getMonth() + 1):(new Date(date).getMonth() + 1)) + '/' + new Date(date).getFullYear();
        return dateFormated;
    }

    //FUNÇÃO PARA LISTAR OS CARDS
    function loadCalendar(){
        axios.get('https://local.fastview.com.br/systems/job-novo/api/list-react.php?do=get_list&filter_date_start='+cd(periodStart)+'&filter_date_end='+cd(periodEnd)+'&filter_id_store=&filter_id_user='+usuario+'&_=1668707669689&filter_type='+tipo).then((response) => {
            setJobs(response.data);
            if(swiper){
                swiper.slideTo(currentDay-1,0); 
            }
        })  
    };

    //FUNÇÃO PARA TROCA DE STATUS DOS CARDS
    function changeStatus(id, status, msg){
        axios.post('https://local.fastview.com.br/systems/job-novo/api/list-react.php?do=set_status',{
            id:id,
            status:status,
            msg: msg
        }).then(() => {
            loadCalendar();
        })  
    };

    //FUNÇÃO PARA TROCA DE STATUS SUPERVISOR DOS CARDS
    function changeStatusSupervisor(id, status, msg){
        axios.post('https://local.fastview.com.br/systems/job-novo/api/list-react.php?do=set_statusSupervisor',{
            id:id,
            status:status,
            msg: msg
        }).then(() => {
            loadCalendar();
        })  
    };

    function editarJob(id){
        window.location.href = '/systems/jobs/novo/'+id;
    }

    //OPTIONS DO SELECT TITLE
    const optionsTipos= [
        { value: 1, label: 'Data' },
        { value: 2, label: 'Loja' },
        { value: 3, label: 'Painel' },
        { value: 4, label: 'Usuário' },
    ]

    const optionsLojas = [
        { value: 10567, label: 'Filial 001' },
        { value: 10525, label: 'Filial 002' },
        { value: 60209, label: 'Filial 003' },
    ]

    //INSTANCIANDO VARIÁVEIS
    var background, actions, obs2, obs3;

    //PEGAR DATA E HORA ATUAL
    var currentDateValue = new Date();
    var currentDay = currentDateValue.getDate();
    var currentMonth = currentDateValue.getMonth()+1;
    var currentYear = currentDateValue.getFullYear();
    var currentHour = currentDateValue.getHours();
    var currentMinutes = currentDateValue.getMinutes();
    var currentSeconds = currentDateValue.getSeconds();
    var currentDate = currentYear+'-'+currentMonth+'-'+currentDay+' '+currentHour+':0'+currentMinutes+':'+currentSeconds;    

    // CARREGAMENTOS INICIAIS
    useEffect(() => {
        loadCalendar();

        axios.get('https://local.fastview.com.br/api/sql-react.php?do=select&component=usuario&np=true&limit=50').then((response) => {
            setOptionsUser(response.data);
        })
    }, [swiper]);

    return(        
        <>
            <Title className="mb-5">                
                <TitleFilter margin={false} align="center">
                    <SelectReact
                        options={optionsTipos}
                        name="filter_type"
                        defaultValue={1}
                        value={optionsTipos[tipo-1]}
                        onChange={(e) => (setTipo(e.value), setButtonDisabled(false))}
                    />
                    <SelectReact
                        options={optionsLojas}
                        name="filter_id_store"
                        value={optionsLojas[loja]}
                        onChange={(e) => (setLoja(e.value), setButtonDisabled(false))}
                        hide={(tipo==2||tipo==3?false:true)}
                    />
                    <SelectReact
                        options={optionsUser}
                        name="filter_id_user"
                        defaultValue={window.rs_usuario_id}
                        value={optionsUser[usuario]}
                        onChange={(e) => (setUsuario(e.value), setButtonDisabled(false))}
                    />
                    <Input
                        type="period"
                        name="period_start"
                        required={false}
                        valueStart={periodStart}
                        valueEnd={periodEnd}
                        onChangeStart={(e) => (setPeriodStart(e), setButtonDisabled(false))}
                        onChangeEnd={(e) => (setPeriodEnd(e), setButtonDisabled(false))}
                    />

                    <Button onClick={() => (loadCalendar(), setButtonDisabled(true), setToastShow(true))} disabled={(buttonDisabled?true:'')}>Filtrar</Button>
                </TitleFilter>
            </Title>  

            <Swiper
                modules={[Navigation]}
                autoHeight={false}
                slidesPerView={1}
                spaceBetween={15}  
                onSwiper={(swiper) => setSwiper(swiper)}   
                navigation   
                loop={false}
                allowTouchMove={true}
                breakpoints={{
                    500: {
                        slidesPerView:'auto',
                        allowTouchMove:false
                    }
                }}
            >                

                {jobs.length?
                    jobs?.map((jobs,i) => {  
                        return (           
                            <SwiperSlide key={i}>
                                <Title>{jobs.title}</Title>
                                {jobs.cards.length?
                                    jobs.cards?.map((card) => {   
                                        {(() => { // CORES
                                            if(card.status==0&&(card.data_job>currentDate)){
                                                background = '';
                                            }else if(card.status==0&&(card.data_job<currentDate&&card.status_supervisor!=3)){ // ATRASADO
                                                background = 'red';
                                            }else if(card.status==0&&(card.data_job<currentDate&&card.status_supervisor==3)){ // ATRASADO REABERTO
                                                background = '';
                                            }else if(card.status==1&&(card.data_fim_formatada<currentDate)){ // CONCLUÍDO NO PRAZO
                                                background = 'blue';
                                            }else if(card.status==2){
                                                background = 'blue';
                                            }else if(card.status==3){ // CONCLUÍDO COM ATRASO
                                                background = 'orange';
                                            }
                                        })()}
                                        {(() => { // BOTÕES DE AÇÃO
                                            if(card.status==0&&(card.data_job<=currentDate)){ // JOB EM STATUS PADRÃO
                                                actions = 
                                                    <>
                                                        <Icon type="aprovar" title="Finalizar" onClick={() => changeStatus(card.id_job_status, (card.data_job<currentDate?3:1), '')} />
                                                        <Icon type="reprovar" title="Não tem" onClick={() => changeStatus(card.id_job_status, 2, '')} />
                                                        <Icon type="calendar" title="Adiar" />
                                                        <Icon type="editar" title="Editar" onClick={() => editarJob(card.lote)} />
                                                    </>
                                                ;
                                            }else if(card.status==0&&(card.data_job>currentDate)){ // JOB DE DATA FUTURA
                                                actions = 
                                                    <>
                                                        <Icon type="editar" title="Editar" onClick={() => editarJob(card.lote)} />
                                                    </>
                                                ;
                                            }else if((card.status==1||card.status==2)&&!card.status_supervisor){ // JOB FINALIZADO PELO OPERADOR SEM AVALIAÇÃO DO SUPERVISOR
                                                actions = 
                                                    <>
                                                        <Icon type="reabrir" title="Reabrir" onClick={() => changeStatusSupervisor(card.id_job_status, 3, '')} />
                                                        <Icon type="aprovar" title="Aprovar" onClick={() => changeStatusSupervisor(card.id_job_status, 1, '')} />
                                                        <Icon type="reprovar" title="Reprovar" onClick={() => changeStatusSupervisor(card.id_job_status, 2, '')} />
                                                    </>
                                                ;
                                            }else if(card.status==1&&card.status_supervisor==1){ // JOB FINALIZADO PELO OPERADOR COM AVALIAÇÃO DO SUPERVISOR
                                                actions = 
                                                    <></>
                                                ;
                                            }else if(card.status==2){
                                                actions = 
                                                    <>
                                                        <Icon type="reabrir" />
                                                        <Icon type="aprovar" onClick={() => changeStatus(card.id_job_status, 1, '')} />
                                                        <Icon type="reprovar" />
                                                    </>
                                                ;
                                            }else if(card.status==3){
                                                actions = 
                                                    <>
                                                        <Icon type="reabrir" />
                                                        <Icon type="aprovar" onClick={() => changeStatus(card.id_job_status, 1, '')} />
                                                        <Icon type="reprovar" />
                                                    </>
                                                ;
                                            }
                                        })()}
                                        {(() => { // OBSERVAÇÕES
                                            if(card.status==1){
                                                obs2 = 'Feito: '+card.data_execucao_formatada_hora;
                                            }else if(card.status==2){
                                                obs2 = 'Não tem: '+card.data_execucao_formatada_hora;
                                            }else if(card.status==3){
                                                obs2 = 'Feito com atraso: '+card.data_execucao_formatada_hora;
                                            }else{
                                                obs2 = '';
                                            }
                                        })()} 

                                        {(() => { // STATUS SUPERVISOR
                                            if(card.status_supervisor==1){
                                                obs3 = 'Aprovado';
                                            }else if(card.status_supervisor==2){
                                                obs3 = 'Reprovado';
                                            }else if(card.status==0&&(card.data_job<currentDate&&card.status_supervisor==3)){
                                                obs3 = 'Reaberto';
                                            }else{
                                                obs3 = '';
                                            }
                                        })()} 

                                        return(
                                            <Card
                                                key={card.id_job}
                                                id={card.id_job}
                                                title={card.job}
                                                subtitle={(card.categoria +' / '+card.subcategoria)}
                                                obs1={card.frequencia+' '+card.hora_limite_formatada}
                                                obs2={obs2}
                                                obs3={obs3}
                                                actions={actions}
                                                background={background}
                                                description={card.descricao}
                                            >
                                                <Form>
                                                    <Textarea name="observacao" label="Observação" required={false} />
                                                    <Button type="submit">Enviar</Button>
                                                </Form>
                                            </Card>
                                        )
                                    })
                                :
                                    <Card empty={true}></Card> 
                                }
                            </SwiperSlide>
                        )                         
                    })                    
                :
                    <PageEmpty />
                }
            </Swiper>

            <Toast text="Filtro aplicado" show={toastShow} />
        </>
    )
}
