import { useState } from 'react';
import Select from 'react-select';
import style from './Select.module.scss';

export default function SelectReact(props){
    const [focus, setFocus] = useState(null);

    const customStyles = {      
        indicatorSeparator: () => ({
            display: 'none'
        }),
        dropdownIndicator: (base) => {
            return {
                ...base,
                width: 15,
                padding: '8px 0 8px 0',
            }
        },
        menu: (base) => {
            return {
                ...base,
                border: '1px solid #f9f9f9',
                boxShadow: '0 0 10px -5px rgb(0 0 0 / 50%)',
                padding: 0,
                width: "max-content",
                minWidth: "100%"
            };
        },
        menuList: (base) => {
            return {
                ...base,
                padding: 0,                
            }
        },
        placeholder: (base) => {
            return{
                display:'none'
            }
        },
        valueContainer: (base) => {
            return {
                ...base,
                paddingLeft:0,
                paddingRight:4
            }
        },
        noOptionsMessage: (base) => {
            return {
                ...base,
                fontSize: 14,
                fontWeight: 400,
            }
        },
        control: () => ({
            border: 0,
            boxShadow: 'none',
            backgroundColor: '#fff',
            borderRadius: 4,
            fontSize: 13,
            fontWeight: 400,
            display: 'flex',
            cursor: 'pointer'
        }),
        option: (base, { isFocused }) => {
            return {
                ...base,
                borderBottom: 'none',
                padding: 10,
                fontSize: 13,
                fontWeight: 400,
                cursor: 'pointer',
                backgroundColor: isFocused ? '#eff2f3' : '#fff',
                color: '#2a2e36',
                overflow: 'visible'
            };
        }
    }

    return(
        <div
            data-select
            className={
                style.box__select + ' ' + 
                (props.fullWidth?'w-100':'') + ' ' + 
                (props.className?props.className:'') + ' ' + 
                (props.type=='checkbox'?style.checkbox:'') + ' ' +
                (focus?style.box__select_focus:'')
            }
            style={{
                display:(props.hide?'none':'')
            }}
        >
            {(() => {
                if(props.label){
                    return(
                        <label htmlFor={ props.id }>
                            { props.label }: { (props.required===false?'':'*') }
                        </label>
                    )
                }
            })()}

            <Select
                defaultValue={ props.defaultValue }
                options={ props.options }
                styles={customStyles}
                required={ props.required }
                value={ 
                    (props.value?
                        props.value
                    :
                        props.options.filter(option => 
                           option.value === parseInt(props.defaultValue)
                        )
                    )
                }
                onChange={ props.onChange }
                noOptionsMessage={() => 'Nenhuma opção disponível'}
            />
        </div>
    );
}
