import { useState } from "react";
import { Modal as ModalBoostrap } from 'react-bootstrap';

export default function Modal(props){
    const [show, setShow] = useState(false);

    return(
        <>
            <ModalBoostrap show={ props.show } onHide={ props.onHide }>
                { props.children }
            </ModalBoostrap>
        </>
    );
}
