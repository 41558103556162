import { Routes, Route } from 'react-router-dom';
import Sidebar from '../../components/body/sidebar';
import SidebarLink from '../../components/body/sidebar/sidebarlink';
import Gerenciador from './Gerenciador';

// PÁGINAS
import Supervisor from './Supervisor';

export default function Supervisao(){
  return (
    <>
        <Sidebar>
          <SidebarLink link="/systems/supervisao/supervisor" name="Supervisão" />
          <SidebarLink link="/systems/supervisao/gerenciador" name="Gerenciador" />
        </Sidebar>

        <Routes>
          <Route index={true} element={ <Supervisor /> } />
          <Route path="supervisor" element={ <Supervisor /> } />
          <Route path="gerenciador" element={ <Gerenciador /> } />
        </Routes>
    </>
  );
}
