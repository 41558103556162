import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

export default function Lightbox(props){
    return(
        <FsLightbox
            toggler={ props.toggler }
            sources={ props.sources }
        />
    );
}
