import { useState } from 'react';
import style from './Sidebar.module.scss';

export default function Sidebar(props){
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return(
        <div className={ style.sidebar + ' ' + (isHovering?style.sidebar__active:'') } onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <ul>
                { props.children }
            </ul>
        </div>
    );
}
