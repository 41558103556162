import { useEffect, useState } from "react";

import Title from "../../../components/body/title";
import TitleFilter from "../../../components/body/title/titlefilter";
import TitleActions from "../../../components/body/title/titleActions";
import Table from "../../../components/body/table";
import Tr from "../../../components/body/table/tr";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import ThFilter from "../../../components/body/table/thead/thFilter";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Icon from "../../../components/body/icon";
import SelectReact from "../../../components/body/select";
import Modal from '../../../components/body/modal';
import ModalHeader from '../../../components/body/modal/modalHeader';
import ModalTitle from '../../../components/body/modal/modalHeader/modalTitle';
import ModalBody from '../../../components/body/modal/modalBody';
import Form from "../../../components/body/form";
import Input from "../../../components/body/form/input";
import Textarea from "../../../components/body/form/textarea";
import Button from '../../../components/body/button';
import Row from "../../../components/body/row";
import Col from "../../../components/body/col";

import axios from "axios";

export default function Lista(){
    const [comunicados, setComunicados] = useState([]);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    //OPTIONS DO SELECT TITLE
    const options = [
        { value: 1, label: 'Ativos' },
        { value: 2, label: 'Inativos' },
        { value: 3, label: 'Pendentes' },
    ]

    useEffect(() => {
        axios.get('https://local.fastview.com.br/systems/comunicado/api/lista-react.php?page=1&limit=50').then((response) => {
            setComunicados(response.data);
        })        
    }, []);

    return(
        <>
            {/* MODAL NOVO COMUNICADO */}
            <Modal show={ show } onHide={ handleClose }>
                <ModalHeader>
                    <ModalTitle>
                        Novo <b>Comunicado</b>
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <Input type="text" name="titulo" label="Título" />
                        <Input type="file" name="anexo" label="Anexo" required={false}/>
                        <Textarea name="texto" placeholder="Texto" />
                        <Button type="submit">Salvar</Button>
                    </Form>
                </ModalBody>
            </Modal>

            {/* LISTA */}
            <Row>
                <Col>
                    <Title>
                        Lista
                        
                        <TitleFilter>
                            <SelectReact
                                options={options}
                                value={options[0]}
                            />
                        </TitleFilter>

                        <TitleActions>
                            <Icon type="excel" />
                            <Icon type="print" />
                            <Icon type="new" onClick={handleShow} />
                        </TitleActions>
                    </Title>                        

                    <Table>
                        <Thead>
                            <Tr>
                                <ThFilter name="titulo">Título</ThFilter>
                                <ThFilter name="loja">Lojas</ThFilter>
                                <ThFilter name="usuario">Usuário</ThFilter>
                                <ThFilter name="data" type="date">Data</ThFilter>
                                <ThFilter name="termos">Termos e Cond.</ThFilter>
                                <Th>Enviados</Th>
                                <Th>Lidos</Th>
                                <Th>Pendentes</Th>
                                <Th>Receb. s/ leitura</Th>
                                <Th>Ações</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {comunicados?.map((comunicados) => {
                                return <Tr key={ comunicados.id }>
                                            <Td>{comunicados.nome}</Td>
                                            <Td>{comunicados.lojas}</Td>
                                            <Td>{comunicados.usuario}</Td>
                                            <Td>{comunicados.data}</Td>
                                            <Td align="center">{comunicados.termos}</Td>
                                            <Td align="center">{comunicados.enviado}</Td>
                                            <Td align="center">{comunicados.lido}</Td>
                                            <Td align="center">{parseInt(comunicados.enviado) - parseInt(comunicados.lido)}</Td>
                                            <Td align="center">{comunicados.recebido_sem_leitura}</Td>
                                            <Td>
                                                <Icon type="view" />
                                                <Icon type="delete" />
                                            </Td>
                                        </Tr>
                            })}
                        </Tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
}