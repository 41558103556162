import {
    FaChartLine,
    FaCog,
    FaFile,
    FaList,
    FaTh
} from "react-icons/fa";
import { NavLink  } from 'react-router-dom';

export default function SidebarLink(props){
    var icon='';

    switch(props.name){
        case 'Novo':    
            icon = <FaFile />;    
            break;
        case 'Lista':    
            icon = <FaList />;    
            break;
        case 'Painel':
            icon = <FaTh />;    
            break;
        case 'Dashboard':
            icon = <FaChartLine />;    
            break;
        case 'Gerenciador':
            icon = <FaCog />;    
            break;
        default:
            icon = <FaList />;    
    }

    return(        
        <li>
            <NavLink to={ props.link }>
                { icon }
                <span>{props.name }</span>
            </NavLink>
        </li>
    );
}
