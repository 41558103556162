import style from './Icon.module.scss';
import 'tippy.js/dist/tippy.css';
import Tippy from '@tippyjs/react';
import {
    FaEye,
    FaTrash,
    FaExclamationTriangle,
    FaSearch,
    FaRegCalendarAlt,
    FaPlus,
    FaPrint,
    FaFileExcel,
    FaPaperclip,
    FaPlusCircle,
    FaMinusCircle,
    FaCalendarDay,
    FaPencilAlt,
    FaCheck,
    FaTimes,
    FaUndoAlt
} from "react-icons/fa";

export default function Icon(props){
    var iconType, iconTitle;

    switch(props.type){
        case 'new':
        case 'novo':
            iconType = <FaPlus className={ (props.className?props.className:'text-primary') } />;
            iconTitle = (props.title?props.title:'Novo');
            break;
        case 'open':
        case 'abrir':
            iconType = <FaPlusCircle className={ (props.className?props.className:'text-primary') } />;
            iconTitle = (props.title?props.title:'Expandir');
            break;
        case 'close':
        case 'fechar':
            iconType = <FaMinusCircle className={ (props.className?props.className:'text-danger') } />;
            iconTitle = (props.title?props.title:'Expandir');
            break;
        case 'view':
            iconType = <FaEye className={ (props.className?props.className:'') } />;
            iconTitle = (props.title?props.title:'Visualizar');
            break;
        case 'delete':
            iconType = <FaTrash className={ (props.className?props.className:'') } />
            iconTitle = (props.title?props.title:'Excluir');
            break;
        case 'search':
            iconType = <FaSearch className={ (props.className?props.className:'') } />
            iconTitle = (props.title?props.title:'Pesquisar');
            break;
        case 'calendar':
        case 'calendario':
            iconType = <FaCalendarDay className={ (props.className?props.className:'') } />
            iconTitle = (props.title?props.title:'Agendar');
            break;
        case 'print':
            iconType = <FaPrint className={ (props.className?props.className:'text-primary') } onClick={() => (window.print()) } />;
            iconTitle = (props.title?props.title:'Imprimir');
            break;
        case 'excel':
            iconType = <FaFileExcel className={ (props.className?props.className:'') } style={{color:'#1f7244'}} />;
            iconTitle = (props.title?props.title:'Exportar em Excel');
            break;
        case 'file':
            iconType = <FaPaperclip className={ (props.className?props.className:'') }/>;
            iconTitle = (props.title?props.title:'Anexo');
            break;
        case 'aprovar':
            iconType = <FaCheck className={ (props.className?props.className:'') }/>;
            iconTitle = (props.title?props.title:'Aprovar');
            break;
        case 'reprovar':
            iconType = <FaTimes className={ (props.className?props.className:'') }/>;
            iconTitle = (props.title?props.title:'Reprovar');
            break;
        case 'reabrir':
            iconType = <FaUndoAlt className={ (props.className?props.className:'') }/>;
            iconTitle = (props.title?props.title:'Reabrir');
            break;
        case 'editar':
        case 'edit':
            iconType = <FaPencilAlt className={ (props.className?props.className:'') }/>;
            iconTitle = (props.title?props.title:'Editar');
            break;
        default:
            iconType = <FaExclamationTriangle className={ (props.className?props.className:'') } />
            iconTitle = (props.title?props.title:'ERRO: Ícone não encontrado');
    }

    return(
        <>
            {(() => {
                return(
                    <Tippy content={ iconTitle } >
                        <div className={ style.iconContainer } onClick={ props.onClick } >
                            {iconType}
                        </div>
                    </Tippy>
                )
            })()}
        </>
    );
}
