import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './_assets/css/global.scss';
import Nav from './components/header/nav';

// SISTEMAS
import Home from './pages/Home';
import Comunicados from './pages/Comunicados';
import Jobs from './pages/Jobs';
import Supervisao from './pages/Supervisao';

function App() {
  return (
    <>
      <Router>
        <header>
          <Nav></Nav>
        </header>

        <div className="content">
          <Routes>
            <Route path="/" element={ <Home /> } />
            <Route path="/systems/comunicados/*" element={ <Comunicados /> } />
            <Route path="/systems/jobs/*" element={ <Jobs /> } />
            <Route path="/systems/supervisao/*" element={ <Supervisao /> } />
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
