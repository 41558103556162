import { useEffect, useState } from "react";

import Title from "../../../components/body/title";
import TitleFilter from "../../../components/body/title/titlefilter";
import TitleActions from "../../../components/body/title/titleActions";
import Icon from "../../../components/body/icon";
import SelectReact from "../../../components/body/select";
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '../../../_assets/css/swiper.css';
import Foto from "../../../components/body/foto";
import Lightbox from '../../../components/body/ligthbox';

/*FOTOS*/
import Foto1 from '../../../_assets/uplodad/foto1.jpg';
import Foto2 from '../../../_assets/uplodad/foto2.jpg';
import Foto3 from '../../../_assets/uplodad/foto3.jpg';

export default function Lista(){
    const [toggler, setToggler] = useState(false);
    const [img, setImg] = useState(null);

    //OPTIONS DO SELECT TITLE
    const options = [
        { value: 1, label: 'Loja 1' },
        { value: 2, label: 'Loja 2' },
        { value: 3, label: 'Loja 3' },
    ]

    return(
        <>
            {/* LIGHTBOX */}
            <Lightbox toggler={toggler} sources={[img]} />

            {/* LISTA */}
            <div className="row">
                <div className="col-lg-auto">
                    <Title>
                        Supervisão
                        
                        <TitleFilter>
                            <SelectReact
                                options={options}
                                value={options[0]}
                            />
                        </TitleFilter>

                        <TitleActions>
                            <Icon type="print" />
                        </TitleActions>
                    </Title>                 

                    <Title>Loja 1</Title>
                    <Swiper
                        className="mb-4"
                        modules={[Pagination]}
                        autoHeight={false}
                        slidesPerView={1}
                        spaceBetween={15}     
                        pagination={{ clickable: true }}
                        loop={false}
                        allowTouchMove={true}
                        breakpoints={{
                            500: {
                                slidesPerView:'auto',
                                allowTouchMove:true
                            }
                        }}
                    >    

                        <SwiperSlide>
                            <Foto
                                left={Foto1}
                                right={Foto1}
                                onClickLeft={() => (setImg(Foto1), setToggler(!toggler))}
                                onClickRight={() => (setImg(Foto1), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto2}
                                onClickLeft={() => (setImg(Foto2), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto3}
                                right={Foto3}
                                onClickLeft={() => (setImg(Foto3), setToggler(!toggler))}
                                onClickRight={() => (setImg(Foto3), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto1}
                                onClickLeft={() => (setImg(Foto1), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto2}
                                onClickLeft={() => (setImg(Foto2), setToggler(!toggler))}
                            />
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <Foto
                                left={Foto3}
                                onClickLeft={() => (setImg(Foto3), setToggler(!toggler))}
                            />
                        </SwiperSlide>
                    </Swiper>

                    <Title>Loja 2</Title>
                    <Swiper
                        className="mb-4"
                        modules={[Pagination]}
                        autoHeight={false}
                        slidesPerView={1}
                        spaceBetween={15}     
                        pagination={{ clickable: true }}
                        loop={false}
                        allowTouchMove={true}
                        breakpoints={{
                            500: {
                                slidesPerView:'auto',
                                allowTouchMove:true
                            }
                        }}
                    >    

                        <SwiperSlide>
                            <Foto
                                left={Foto1}
                                onClickLeft={() => (setImg(Foto1), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto2}
                                right={Foto2}
                                onClickLeft={() => (setImg(Foto2), setToggler(!toggler))}
                                onClickRight={() => (setImg(Foto2), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto3}
                                onClickLeft={() => (setImg(Foto3), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto1}
                                onClickLeft={() => (setImg(Foto1), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto2}
                                onClickLeft={() => (setImg(Foto2), setToggler(!toggler))}
                            />
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <Foto
                                left={Foto3}
                                onClickLeft={() => (setImg(Foto3), setToggler(!toggler))}
                            />
                        </SwiperSlide>
                    </Swiper>

                    <Title>Loja 3</Title>
                    <Swiper
                        className="mb-4"
                        modules={[Pagination]}
                        autoHeight={false}
                        slidesPerView={1}
                        spaceBetween={15}     
                        pagination={{ clickable: true }}
                        loop={false}
                        allowTouchMove={true}
                        breakpoints={{
                            500: {
                                slidesPerView:'auto',
                                allowTouchMove:true
                            }
                        }}
                    >    

                        <SwiperSlide>
                            <Foto
                                left={Foto1}
                                onClickLeft={() => (setImg(Foto1), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto2}
                                onClickLeft={() => (setImg(Foto2), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto3}
                                onClickLeft={() => (setImg(Foto3), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto1}
                                onClickLeft={() => (setImg(Foto1), setToggler(!toggler))}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Foto
                                left={Foto2}
                                onClickLeft={() => (setImg(Foto2), setToggler(!toggler))}
                            />
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <Foto
                                left={Foto3}
                                onClickLeft={() => (setImg(Foto2), setToggler(!toggler))}
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </>
    );
}