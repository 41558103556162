import { useState } from 'react';
import style from './textarea.module.scss';

export default function Textarea(props){
    const [focus, setFocus] = useState(null);

    return(
        <div
            data-textarea
            className={
                style.box__textarea + ' ' + 
                (props.fullWidth?'w-100':'') + ' ' + 
                (props.className?props.className:'') + ' ' + 
                (props.type=='checkbox'?style.checkbox:'') + ' ' +
                (focus?style.box__textarea_focus:'')
            }
        >
            {(() => {
                return(
                    <textarea
                        className={ style.input }
                        type={ (props.type==='date'?'text':props.type) }
                        name={ props.name }
                        id={ props.id }
                        placeholder={ (props.label||props.placeholder?(props.placeholder?props.placeholder:props.label):'Textarea')+': '+(props.required===false?'':'*') }
                        onFocus={ () => setFocus(true) }
                        onBlur={ () => setFocus(false) }
                        value={ props.value }
                        onChange={ props.onChange }
                    >
                      { props.value }
                    </textarea>
                )
            })()}          
        </div>
    );
}
