import {
    FaPaperPlane,
    FaExclamationTriangle,
    FaSave,
    FaCheck
} from 'react-icons/fa';
import style from './Button.module.scss';

export default function Button(props){
    var class_aux, icon;

    switch(props.color){
        case 'white':
            class_aux = style.button__primary;
            break;
        default:
            class_aux = style.button__default;
            break;
    }

    switch(props.icon){
        case 'send':
            icon = <FaPaperPlane className="ms-2" />
            break;
        case 'save':
            icon = <FaSave className="ms-2" />
            break;
        default:
            icon = <FaExclamationTriangle className="ms-2" />
            break;
    }

    return(
        <>
            <button onClick={ props.onClick } className={ style.button + ' ' + class_aux + ' ' +(props.type=='submit'?'float-end':'') + ' ' + (props.disabled===true?style.button__disabled:'') + ' ' + (props.status==='loading'||props.status==='carregando'?style.button__disabled:'')} type={ props.type }>
                {(() => {
                    if(props.status==='loading'||props.status==='carregando'){
                        return(
                            <>
                               Carregando

                                <div className="spinner-border ms-2" role="status"></div>
                            </>
                        )
                    }else{
                        return(
                            <>
                               {props.children}
                            </>
                        )
                    }
                })()}

                {(() => {
                    if(props.type=='submit'&&!props.icon&&(props.status!=='loading'&&props.status!=='carregando')){
                        return(
                            <FaCheck className="ms-2" />
                        )
                    }

                    if(props.icon&&(props.status!=='loading'&&props.status!=='carregando')){
                        return(
                            icon
                        )
                    }
                })()}
            </button>
            {(props.type=='submit'?<div style={{'clear':'both'}}></div>:'')}
        </>
    );
}
