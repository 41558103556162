import style from './Table.module.scss';

export default function Table(props){
    var class_text_limit;

    if(props.text_limit===false){
        class_text_limit = '';
    }else{
        if(props.text_limit == 10) {
            class_text_limit = style.text__limit + ' ' + style.text__limit_10;
        }else if(props.text_limit == 20) {
            class_text_limit = style.text__limit + ' ' + style.text__limit_20;
        }else if(props.text_limit == 30) {
            class_text_limit = style.text__limit + ' ' + style.text__limit_30;
        }else if(props.text_limit == 40) {
            class_text_limit = style.text__limit + ' ' + style.text__limit_40;
        }else if(props.text_limit == 50) {
            class_text_limit = style.text__limit + ' ' + style.text__limit_50;
        }else if(props.text_limit == 60) {
            class_text_limit = style.text__limit + ' ' + style.text__limit_60;
        }else if(props.text_limit == 70) {
            class_text_limit = style.text__limit + ' ' + style.text__limit_70;
        }else if(props.text_limit == 80) {
            class_text_limit = style.text__limit + ' ' + style.text__limit_80;
        }else if(props.text_limit == 90) {
            class_text_limit = style.text__limit + ' ' + style.text__limit_90;
        }else if(props.text_limit == 100) {
            class_text_limit = style.text__limit + ' ' + style.text__limit_100;
        }else{
            class_text_limit = style.text__limit + ' ' + style.text__limit_30;
        }
    }
    return(
        <>
            <div className={ style.tableContainer + ' '+(props.fixed===false?'':style.tableContainerFixed)}>
                <table className={ style.text_overflow + ' ' + class_text_limit} >
                    { props.children }
                </table>  
            </div>
        </>
    );
}
