import { Routes, Route, useParams } from 'react-router-dom';
import Sidebar from '../../components/body/sidebar';
import SidebarLink from '../../components/body/sidebar/sidebarlink';

// PÁGINAS
import Novo from './Novo';
import Calendario from './Calendario';

export default function Jobs(){  
  window.token = ''
  window.rs_empreendimento_id = 26; 
  window.rs_usuario_id = 24379;
  window.rs_usuario_nome = 'Henry';

  return (
    <>
        <Sidebar>
          <SidebarLink link={'/systems/jobs/novo'} name="Novo" />
          <SidebarLink link={'/systems/jobs/calendario'} name="Calendário" />
        </Sidebar>

        <Routes>
            <Route exact index={true} element={ <Calendario /> } />
            <Route exact path="novo/:token" element={ <Novo /> } />
            <Route exact path="calendario/:token" element={ <Calendario /> } />
        </Routes>
    </>
  );
}
